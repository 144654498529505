var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({staticClass:"\n    outline-none\n    focus:outline-none\n    hover:outline-none\n    cursor-pointer\n    relative\n    disabled:border-opacity-60\n    disabled:cursor-not-allowed\n  ",class:{
    'disabled:bg-gray-400 disabled:text-white':
      _vm.type == 'primary' || _vm.type == 'secondary',
    'disabled:border-gray-400 disabled:text-gray-400 disabled:bg-transparent':
      _vm.type.includes('outline'),
    'bg-primary hover:bg-primary-dark  text-white': _vm.type == 'primary',
    'lg:px-7 px-5': _vm.padding,
    'bg-secondary text-primary': _vm.type == 'secondary',
    'text-primary border border-solid border-primary bg-transparent hover:bg-primary hover:text-white':
      _vm.type == 'outline-primary',
    'text-secondary border border-solid border-secondary bg-transparent hover:bg-secondary hover:text-gray-900':
      _vm.type == 'outline-secondary',
    'text-white border border-solid border-white bg-transparent hover:bg-white hover:text-primary':
      _vm.type == 'outline-white',
    'text-gray-400 border border-solid border-gray-400 bg-transparent hover:bg-gray-500 hover:text-gray-200':
      _vm.type == 'outline-gray',
    'bg-transparent text-primary': _vm.type == 'link',
    'rounded-full': _vm.rounded,
  },style:(_vm.customStyle),attrs:{"disabled":_vm.disabled,"type":"submit","padding":[_vm.padding],"margin":[_vm.margin]}},_vm.inputListeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }