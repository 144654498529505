// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Roboto-Black.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Roboto-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Roboto-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/Roboto-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/Roboto-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/Roboto-Thin.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{\n  font-family:\"roboto\";\n\n  font-style:normal;\n\n  font-weight:900;\n\n  font-display:swap;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")\n}\n\n@font-face{\n  font-family:\"roboto\";\n\n  font-style:normal;\n\n  font-weight:700;\n\n  font-display:swap;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")\n}\n\n@font-face{\n  font-family:\"roboto\";\n\n  font-style:normal;\n\n  font-weight:500;\n\n  font-display:swap;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")\n}\n\n@font-face{\n  font-family:\"roboto\";\n\n  font-style:normal;\n\n  font-weight:400;\n\n  font-display:swap;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\")\n}\n\n@font-face{\n  font-family:\"roboto\";\n\n  font-style:normal;\n\n  font-weight:300;\n\n  font-display:swap;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\")\n}\n\n@font-face{\n  font-family:\"roboto\";\n\n  font-style:normal;\n\n  font-weight:100;\n\n  font-display:swap;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\")\n}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
