<template>
  <Section padding="pt-16">
    <div
      class="
        border-t border-t-solid border-gray-200
        flex
        md:flex
        py-4
        items-center
        md:py-9
        text-gray-400 text-base
        lg:text-left
        text-center
      "
    >
      <div class="w-1/2 text-left">
        <p class="lg:text-sm md:text-sm sm:text-sm text-xs">
          <nuxt-link to="privacy-policy" target="__blank">{{
            $t('footer.privacy-policy')
          }}</nuxt-link>
        </p>
      </div>
      <div class="w-1/2">
        <a
          target="__blank"
          class="
            flex
            items-center
            justify-end
            relative
            lg:text-sm
            md:text-sm
            sm:text-sm
            space-x-3
            text-xs
          "
        >
          <div class="block">
            <span>Powered by</span>
          </div>
          <div class="block">
            <span><Logo /></span>
          </div>
          <div class="block font-medium">
            <span>Dacade</span>
          </div>
        </a>
      </div>
    </div>
  </Section>
</template>

<script>
import Section from '@/components/ui/Section'
import Logo from '@/components/Logo'

export default {
  name: 'Footer',
  components: {
    Section,
    Logo,
  },
}
</script>
