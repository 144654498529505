/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { TezosToolkit } from '@taquito/taquito'
import { BeaconWallet } from '@taquito/beacon-wallet'
import {
  NetworkType,
  BeaconEvent,
  defaultEventCallbacks,
} from '@airgap/beacon-sdk'
import Package from '../../../package.json'

export const state = () => ({
  publicKey: '',
  beaconWallet: null,
  address: '',
  userBalance: 0,
  beaconConnection: false,
  loading: false,
  wallet: null,
})

export const mutations = {
  setAddress(state, payload) {
    state.address = payload
  },
  setPublicKey(state, payload) {
    state.publicKey = payload
  },
  setWallet(state, payload) {
    state.wallet = payload
  },
  setBeacon(state, payload) {
    state.beaconWallet = payload
  },
}

export const actions = {
  async init({ commit }, wallet) {
    commit('setWallet', wallet)
    const Tezos = new TezosToolkit('https://mainnet.api.tez.ie')
    const beaconWallet = new BeaconWallet({
      name: Package.name,
      preferredNetwork: NetworkType.MAINNET,
      disableDefaultEvents: true, // Disable all events / UI. This also disables the pairing alert.
      eventHandlers: {
        // To keep the pairing alert, we have to add the following default event handlers back
        [BeaconEvent.PAIR_INIT]: {
          handler: defaultEventCallbacks.PAIR_INIT,
        },
        [BeaconEvent.PAIR_SUCCESS]: {
          handler: (data) => {
            // commit('setPublicKey', data.publicKey)
          },
        },
      },
    })
    Tezos.setWalletProvider(beaconWallet)
    // checks if beaconWallet was connected before
    const activeAccount = await beaconWallet.client.getActiveAccount()
    if (activeAccount) {
      const address = await beaconWallet.getPKH()
      commit('setAddress', address)
    }
    return beaconWallet
  },
  async disconnect({ commit, state, dispatch }) {
    const beaconWallet = await dispatch('init', state.wallet)
    if (beaconWallet) {
      await beaconWallet.client.removeAllAccounts()
      beaconWallet.client.removeAllPeers()
      await beaconWallet.client.destroy()
      await dispatch('init', state.wallet)
      this.dispatch('user/wallets/removeAddress', state.wallet.id)
    }
    commit('setAddress', '')
  },
  async connect({ commit, state, dispatch }) {
    const beaconWallet = await dispatch('init', state.wallet)
    try {
      await beaconWallet.requestPermissions({
        network: {
          type: NetworkType.MAINNET,
        },
      })
      // gets user's address
      const address = await beaconWallet.getPKH()
      commit('setAddress', address)
    } catch (error) {
      console.log(error)
    }
    commit('setBeacon', beaconWallet)
  },
}

export const getters = {
  address(state) {
    return state.address
  },
  main(state) {
    return state.main
  },
  list(state) {
    return state.list
  },
}
