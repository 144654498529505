<template>
  <a
    class="fixed bottom-5 right-5 z-999 md:w-16 w-14 cursor-pointer"
    href="https://discord.gg/nmffSP36Vd"
    target="_blank"
  >
    <DiscordButton />
  </a>
</template>
<script>
import DiscordButton from '~/assets/icons/discordButton.svg?inline'
export default {
  components: {
    DiscordButton,
  },
}
</script>
